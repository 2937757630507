import axios from 'axios';

const API_HOST = process.env.API_HOST;
const BASE_API_URL = `${process.env.API_HOST}/api/v1`;

const apiUrl = (path) => `${BASE_API_URL}${path}`;
const loginURL = (returnTo) => `${process.env.API_HOST}/saml/initiate?RelayState=${returnTo}`;
const frontendUrl = (path) => `${process.env.FRONTEND_HOST}${path}`;

const authToken = () => window.localStorage.getItem('jwt');

const authHeaders = () => ({
    Authorization: 'Bearer ' + authToken(),
});

const apiHeaders = () => ({
    ...authHeaders(),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Total',
    'Access-Control-Expose-Headers': 'Total, Per-Page, Page',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Context': 'admin',
});

const callApi = (path, opts = {}) => axios({
    url: apiUrl(path),
    headers: apiHeaders(),
    ...opts,
});

const apiGet = (path, opts = {}) => callApi(path, opts);
const apiPost = (path, data, opts = {}) => callApi(path, { method: 'POST', data, ...opts });

export {
    API_HOST,
    BASE_API_URL,
    apiUrl,
    loginURL,
    frontendUrl,
    authToken,
    authHeaders,
    apiHeaders,
    callApi,
    apiGet,
    apiPost,
};
