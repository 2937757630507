import React from 'react';
import PropTypes from 'prop-types';

import {
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    LoadingIndicator,
    HideOnScroll,
} from 'react-admin';

const useStyles = makeStyles(
    {
        toolbar: {
            marginLeft: 270,
            paddingRight: 24,
        },
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    },
    { name: 'RaAppBar' }
);

const AppBarCustom = (props) => {
    const {
        children,
        className,
        color = 'secondary',
        title,
        ...rest
    } = props;
    const classes = useStyles(props);
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    return (
        <HideOnScroll>
            <MuiAppBar className={className} color={color} {...rest}>
                <Toolbar
                    disableGutters
                    variant={isXSmall ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    {React.Children.count(children) === 0 ? (
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.title}
                            id="react-admin-title"
                        />
                    ) : (
                        children
                    )}
                    <LoadingIndicator />
                </Toolbar>
            </MuiAppBar>
        </HideOnScroll>
    );
};

AppBarCustom.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf([
        'default',
        'inherit',
        'primary',
        'secondary',
        'transparent',
    ]),
};

export default AppBarCustom;
