import React from 'react';
import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { apiHeaders } from '@src/services/api/helpers';
import {
    DateField,
    List,
    Pagination,
    TextField,
    useListContext,
    useRefresh,
    useNotify,
} from 'react-admin';
import { Permissions } from '@src/global/permissions';
import { ExpirationDateField, EditResourceButton } from '@src/custom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { source } from './data';
import {
    getUpdatePositionURL,
} from '@src/containers/dataProvider/requestURL';
import FilterForm from './filter-form';

const HomeArticlesList = (props) => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const handleDragStart = React.useCallback(() => {
        document.body.style.cursor = 'move';
    }, []);

    const handleDragEnd = React.useCallback((result) => {
        document.body.style.cursor = 'inherit';
        const options = {
            headers: apiHeaders(),
        };
        options.method = 'PUT';
        options.body = JSON.stringify({ position: result.destination.index + 1 });
        setLoading(true);
        fetch(getUpdatePositionURL('featured_content', result.draggableId), options)
            .then(() => {
                refresh();
            })
            .catch((e) => {
                notify(`Error: ${e.message}`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [refresh, setLoading, notify]);

    const HomeArticlesList = () => {
        const { ids, data } = useListContext();
        const isDraggable = Permissions('home_articles', 'update') === true;

        if (loading) return null;

        return (
            <TableContainer>
                <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                    <Table aria-label="home articles list">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Order</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Archived Date</TableCell>
                                <TableCell>Expired Date</TableCell>
                                <TableCell>Last Updated</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell># Days on Homepage</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <Droppable droppableId="home_articles">
                            {(provided) => (
                                <TableBody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {ids.map((id, index) => {
                                        return (
                                            <Draggable
                                                key={id}
                                                draggableId={id}
                                                index={index}
                                                isDragDisabled={!isDraggable}
                                            >
                                                {(provided, snapshot) => (
                                                    <TableRow
                                                        ref={provided.innerRef}
                                                        key={id}
                                                        hover
                                                        tabIndex={-1}
                                                        className={snapshot.isDragging ? 'dragging' : ''}
                                                        {...provided.draggableProps}
                                                    >
                                                        <TableCell
                                                            style={{ display: isDraggable ? 'table-cell' : 'none' }}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <span><DragHandleIcon /></span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="Order"
                                                                record={data[id]}
                                                                source="position"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="Title"
                                                                record={data[id]}
                                                                source="title"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="Type"
                                                                record={data[id]}
                                                                source="record_type"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <DateField
                                                                label="Archived Date"
                                                                record={data[id]}
                                                                source="archived_at"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <ExpirationDateField
                                                                label="Expired Date"
                                                                record={data[id]}
                                                                source="expired_at"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <DateField
                                                                label="Last Updated"
                                                                record={data[id]}
                                                                source="updated_at"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="Status"
                                                                record={data[id]}
                                                                source="status"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="# Days on Homepage"
                                                                record={data[id]}
                                                                source="days_on_homepage"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <EditResourceButton record={data[id]} />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </TableContainer>
        );
    };

    const ArticlesPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

    return (
        <List
            {...props}
            title="Featured Content"
            filters={<FilterForm />}
            filterDefaultValues={{
                expiration: 'not_expired',
                status: 'published',
                archived: 'false',
            }}
            sort={{ field: source.position, order: 'ASC' }}
            pagination={<ArticlesPagination />}
        >
            <HomeArticlesList />
        </List>
    );
};

export default HomeArticlesList;
