/* eslint-disable complexity */
import React from 'react';
import {
    Admin,
    Resource,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    Dashboard,
} from '@src/components/dashboard';

import {
    AttachmentsList,
    EditAttachment,
} from '@src/components/attachments';

import {
    BusinessUnitsList,
} from '@src/components/business_units';

import {
    ProductUpdateList,
    EditProductUpdate,
    CreateProductUpdate,
} from '@src/components/product_updates';

import {
    ProductAvailabilityList,
    EditProductAvailability,
} from '@src/components/product_availability_map';

import { CarrierCommissionsList } from '@src/components/CarrierCommissions';
import {
    CarrierCommissionImportsList,
    CreateCarrierCommissionImport,
    ShowCarrierCommissionImport,
} from '@src/components/CarrierCommissions/Imports';

import {
    CategoryList,
    CategoryEdit,
    CategoryCreate,
} from '@src/components/categories';

import {
    CategorizationList,
} from '@src/components/categorizations';

import {
    ContactFormsList,
    ShowContactForm,
    QuoteInquiriesList,
} from '@src/components/contact_forms';

import {
    CreateRateAndRuleManual,
    EditRateAndRuleManual,
    RateAndRuleManualsList,
    CreateUnderwritingGuideline,
    EditUnderwritingGuideline,
    UnderwritingGuidelinesList,
} from '@src/components/guides';

import {
    FieldManagementNewsList,
    FieldManagementNewsCreate,
    FieldManagementNewsEdit,
} from '@src/components/field_management_news';

import {
    HomeLinksList,
    CreateHomeLink,
    EditHomeLink,
} from '@src/components/home_links';

import {
    HomeArticlesList,
} from '@src/components/home_articles';

import {
    SocialSharingsList,
    CreateSocialSharing,
    EditSocialSharing,
} from '@src/components/social_sharings';

import {
    TrainingsList,
    CreateTraining,
    EditTraining,
} from '@src/components/training/trainings';

import {
    JobAidsList,
    CreateJobAid,
    EditJobAid,
} from '@src/components/training/jobaids';

// import { errorSaga } from '../sagas';

import {
    WebinarsList,
    CreateWebinar,
    EditWebinar,
} from '@src/components/training/webinars';
import {
    AlertsList,
    AlertsCreate,
    AlertsEdit,
} from '@src/components/alerts';

import { EditRSMAgent360, RSMAgent360List } from '@src/components/rsm-agent360';

import {
    AdminsList,
    AgentsList,
    CreateAdmin,
    CreateHomeOfficeEmployee,
    EditAdmin,
    EditHomeOfficeEmployee,
    HomeOfficeEmployeesList,
    ShowAgent,
} from '@src/components/users';

import {
    RedirectsList,
    RedirectsCreate,
    RedirectsEdit,
} from '@src/components/redirects';

import {
    RolesList,
    CreateRole,
    EditRole,
} from '@src/components/roles';

import {
    BrandsList,
    CreateBrand,
    EditBrand,
} from '@src/components/brands';

import {
    CoBrandedVideoCategoriesList,
    CreateCoBrandedVideoCategory,
    EditCoBrandedVideoCategory,
} from '@src/components/co_branded_video_categories';

import {
    CoBrandedVideoLogosList,
    CreateCoBrandedVideoLogo,
    EditCoBrandedVideoLogo,
} from '@src/components/co_branded_video_logos';

import {
    CoBrandedVideoTemplatesList,
    CreateCoBrandedVideoTemplate,
    EditCoBrandedVideoTemplate,
} from '@src/components/co_branded_video_templates';

import {
    CoBrandedVideoRequestsList,
    EditCoBrandedVideoRequest,
} from '@src/components/co_branded_video_requests';

import { ItemExpirationsList, PageExpirationsList } from '@src/components/expirations';

import { ImagesList, EditImage } from '@src/components/images';

import Error404 from '@src/components/error/Error404';

import routes from '@src/routes/routes';
import './app.scss';
import Auth from './auth/auth';
import SessionInactivityCheck from './auth/sessionInactivityCheck';
import dataProvider from './dataProvider/dataProvider';
import { createBrowserHistory } from 'history';

import { LayoutCustom } from '@src/custom';
import { Taxonomies } from '@src/global/taxonomies';
import i18nProvider from './i18nProvider';
import theme from './theme';

export const history = createBrowserHistory();

const App = () => (
    <Auth>
        <SessionInactivityCheck>
            <Admin
                // customSagas={[errorSaga]}
                dashboard={Dashboard}
                theme={theme}
                layout={LayoutCustom}
                catchAll={Error404}
                customRoutes={routes}
                dataProvider={dataProvider}
                history={history}
                i18nProvider={i18nProvider}
            >
                <Resource
                    name="business_units"
                    list={BusinessUnitsList}
                />
                <Resource
                    name="featured_content"
                    list={Permissions('home_articles', 'read') === true ? HomeArticlesList : Error404}
                />
                <Resource
                    name="product_updates"
                    list={Permissions('product_updates', 'read') === true ? ProductUpdateList : Error404}
                    create={Permissions('product_updates', 'create') === true ? CreateProductUpdate : Error404}
                    edit={Permissions('product_updates', 'update') === true ? EditProductUpdate : Error404}
                />
                <Resource
                    name="contact_forms"
                    list={Permissions('form_entries', 'read') === true ? ContactFormsList : Error404}
                    show={Permissions('form_entries', 'read') === true ? ShowContactForm : Error404}
                />
                <Resource
                    name="products"
                />
                <Resource
                    name="all_products"
                    list={Permissions('products', 'read') === true ? ProductAvailabilityList : Error404}
                    edit={Permissions('products', 'update') === true ? EditProductAvailability : Error404}
                />
                <Resource
                    name="states"
                />
                <Resource
                    name="sales_channels"
                />
                <Resource
                    name="carrier_commissions/imports"
                    create={CreateCarrierCommissionImport}
                    list={CarrierCommissionImportsList}
                    show={ShowCarrierCommissionImport}
                />
                <Resource
                    name="carrier_commissions"
                    list={CarrierCommissionsList}
                />
                <Resource
                    name="home_links"
                    list={Permissions('home_links', 'read') === true ? HomeLinksList : Error404}
                    create={Permissions('home_links', 'create') === true ? CreateHomeLink : Error404}
                    edit={Permissions('home_links', 'update') === true ? EditHomeLink : Error404}
                />
                <Resource
                    name={`taxonomies/${Taxonomies.fieldManagementUpdates}/categories`}
                    list={Permissions('categories', 'read') === true ? CategoryList : Error404}
                    create={Permissions('categories', 'create') === true ? CategoryCreate : Error404}
                    edit={Permissions('categories', 'update') === true ? CategoryEdit : Error404}
                />
                <Resource
                    name={`taxonomies/${Taxonomies.productNewsAndUpdates}/categories`}
                    list={Permissions('categories', 'read') === true ? CategoryList : Error404}
                    create={Permissions('categories', 'create') === true ? CategoryCreate : Error404}
                    edit={Permissions('categories', 'update') === true ? CategoryEdit : Error404}
                />
                <Resource
                    name={`taxonomies/${Taxonomies.jobAids}/categories`}
                    list={Permissions('categories', 'read') === true ? CategoryList : Error404}
                    create={Permissions('categories', 'create') === true ? CategoryCreate : Error404}
                    edit={Permissions('categories', 'update') === true ? CategoryEdit : Error404}
                />
                <Resource
                    name={`taxonomies/${Taxonomies.systemAndTechnical}/categories`}
                    list={Permissions('categories', 'read') === true ? CategoryList : Error404}
                    create={Permissions('categories', 'create') === true ? CategoryCreate : Error404}
                    edit={Permissions('categories', 'update') === true ? CategoryEdit : Error404}
                />
                <Resource
                    name={`taxonomies/${Taxonomies.safetyTips}/categories`}
                    list={Permissions('categories', 'read') === true ? CategoryList : Error404}
                    create={Permissions('categories', 'create') === true ? CategoryCreate : Error404}
                    edit={Permissions('categories', 'update') === true ? CategoryEdit : Error404}
                />
                <Resource
                    name={`taxonomies/${Taxonomies.holidayAndSeasonal}/categories`}
                    list={Permissions('categories', 'read') === true ? CategoryList : Error404}
                    create={Permissions('categories', 'create') === true ? CategoryCreate : Error404}
                    edit={Permissions('categories', 'update') === true ? CategoryEdit : Error404}
                />
                <Resource
                    name="categorizations"
                    list={CategorizationList}
                />
                <Resource
                    list={Permissions('rate_bulletins', 'read') === true ? RateAndRuleManualsList : Error404}
                    create={Permissions('rate_bulletins', 'create') === true ? CreateRateAndRuleManual : Error404}
                    edit={Permissions('rate_bulletins', 'update') === true ? EditRateAndRuleManual : Error404}
                    name="rate_bulletins"
                />
                <Resource
                    name="underwriting_guidelines"
                    list={Permissions('underwriting_guidelines', 'read') === true ? UnderwritingGuidelinesList : Error404}
                    edit={Permissions('underwriting_guidelines', 'update') === true ? EditUnderwritingGuideline : Error404}
                    create={Permissions('underwriting_guidelines', 'create') === true ? CreateUnderwritingGuideline : Error404}
                />
                <Resource
                    name="trainings"
                    list={Permissions('trainings', 'read') === true ? TrainingsList : Error404}
                    edit={Permissions('trainings', 'update') === true ? EditTraining : Error404}
                    create={Permissions('trainings', 'create') === true ? CreateTraining : Error404}
                />
                <Resource
                    name="job_aids"
                    list={Permissions('job_aids', 'read') === true ? JobAidsList : Error404}
                    edit={Permissions('job_aids', 'update') === true ? EditJobAid : Error404}
                    create={Permissions('job_aids', 'create') === true ? CreateJobAid : Error404}
                />
                <Resource
                    name="webinars"
                    list={Permissions('webinars', 'read') === true ? WebinarsList : Error404}
                    edit={Permissions('webinars', 'update') === true ? EditWebinar : Error404}
                    create={Permissions('webinars', 'create') === true ? CreateWebinar : Error404}
                />
                <Resource
                    name="field_management_news"
                    list={FieldManagementNewsList}
                    edit={FieldManagementNewsEdit}
                    create={FieldManagementNewsCreate}
                />
                <Resource
                    name="rsm_agent360"
                    list={RSMAgent360List}
                    edit={EditRSMAgent360}
                />
                <Resource
                    name="alerts"
                    list={Permissions('alerts', 'read') === true ? AlertsList : Error404}
                    edit={Permissions('alerts', 'update') === true ? AlertsEdit : Error404}
                    create={Permissions('alerts', 'create') === true ? AlertsCreate : Error404}
                />
                <Resource
                    name="admins"
                    list={Permissions('users', 'read') === true ? AdminsList : Error404}
                    create={Permissions('users', 'create') === true ? CreateAdmin : Error404}
                    edit={Permissions('users', 'update') === true ? EditAdmin : Error404}
                />
                <Resource
                    name="employees"
                    list={Permissions('users', 'read') === true ? HomeOfficeEmployeesList : Error404}
                    create={Permissions('users', 'create') === true ? CreateHomeOfficeEmployee : Error404}
                    edit={Permissions('users', 'update') === true ? EditHomeOfficeEmployee : Error404}
                />
                <Resource
                    name="agents"
                    list={Permissions('users', 'read') === true ? AgentsList : Error404}
                    show={Permissions('users', 'read') === true ? ShowAgent : Error404}
                />
                <Resource
                    name="quote_inquiries"
                    list={QuoteInquiriesList}
                    show={ShowContactForm}
                />
                <Resource
                    name="social_sharings"
                    list={SocialSharingsList}
                    create={CreateSocialSharing}
                    edit={EditSocialSharing}
                />
                <Resource
                    name="images"
                    list={Permissions('images', 'read') === true ? ImagesList : Error404}
                    edit={Permissions('images', 'update') === true ? EditImage : Error404}
                />
                <Resource
                    name="attachments"
                    list={AttachmentsList}
                    edit={EditAttachment}
                />
                <Resource
                    name="brands"
                    list={Permissions('co_branded_videos', 'read') === true ? BrandsList : Error404}
                    create={Permissions('co_branded_videos', 'create') === true ? CreateBrand : Error404}
                    edit={Permissions('co_branded_videos', 'update') === true ? EditBrand : Error404}
                />
                <Resource
                    name="co_branded_video_categories"
                    list={Permissions('co_branded_videos', 'read') === true ? CoBrandedVideoCategoriesList : Error404}
                    create={Permissions('co_branded_videos', 'create') === true ? CreateCoBrandedVideoCategory : Error404}
                    edit={Permissions('co_branded_videos', 'update') === true ? EditCoBrandedVideoCategory : Error404}
                />
                <Resource
                    name="co_branded_video_logos"
                    list={Permissions('co_branded_videos', 'read') === true ? CoBrandedVideoLogosList : Error404}
                    create={Permissions('co_branded_videos', 'create') === true ? CreateCoBrandedVideoLogo : Error404}
                    edit={Permissions('co_branded_videos', 'update') === true ? EditCoBrandedVideoLogo : Error404}
                />
                <Resource
                    name="co_branded_video_templates"
                    list={Permissions('co_branded_videos', 'read') === true ? CoBrandedVideoTemplatesList : Error404}
                    create={Permissions('co_branded_videos', 'create') === true ? CreateCoBrandedVideoTemplate : Error404}
                    edit={Permissions('co_branded_videos', 'update') === true ? EditCoBrandedVideoTemplate : Error404}
                />
                <Resource
                    name="co_branded_video_requests"
                    list={Permissions('co_branded_videos', 'read') === true ? CoBrandedVideoRequestsList : Error404}
                    edit={Permissions('co_branded_videos', 'update') === true ? EditCoBrandedVideoRequest : Error404}
                />
                <Resource
                    name="item_expirations"
                    list={Permissions('page_expirations', 'read') === true ? ItemExpirationsList : Error404}
                />
                <Resource
                    name="page_expirations"
                    list={Permissions('page_expirations', 'read') === true ? PageExpirationsList : Error404}
                />
                <Resource name="writing_companies" />
                <Resource name="reassignment" />
                <Resource
                    name="redirects"
                    list={Permissions('redirects', 'read') === true ? RedirectsList : Error404}
                    edit={Permissions('redirects', 'update') === true ? RedirectsEdit : Error404}
                    create={Permissions('redirects', 'create') === true ? RedirectsCreate : Error404}
                />
                <Resource
                    name="roles"
                    list={Permissions('roles', 'read') === true ? RolesList : Error404}
                    create={Permissions('roles', 'create') === true ? CreateRole : Error404}
                    edit={Permissions('roles', 'update') === true ? EditRole : Error404}
                />
            </Admin>
        </SessionInactivityCheck>
    </Auth>
);

export default App;
